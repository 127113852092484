import React from 'react';

function WithHook(hook, Component) {
  return function WrappedComponent(props) {
    const hookValue = hook();
    return <Component {...props} withHook={hookValue} />;
  };
}

export default WithHook;
