import { useStaticQuery, graphql } from 'gatsby';

const menuServicesHook = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulService (
        sort: {
          fields:displayWeight,
          order: DESC,
        }
      ) {
        edges {
          node {
            id
            name
            fields {
              slug
            }
          }
        }
      }
    }
  `);

  const menuServices = data.allContentfulService.edges.map(edge => ({
    key: edge.node.id,
    name: edge.node.name,
    slug: edge.node.fields.slug,
  }));

  return menuServices;
};

export default menuServicesHook;
