import React from 'react';
import styles from './Footer.module.scss';
import footerLogo from '../../images/Timberland-logo2.svg';


const Footer = ({ className }) => (
  <footer className={[styles.footer, className].join(' ')}>
    <div className={styles.container}>
      <div className={styles.content}>
        Copyright©
        {new Date().getFullYear()}
        &nbsp;Timberland Joinery. All Rights Reserved.
        &nbsp;
        <a href="/privacypolicy" hrefLang="en" target="_self" rel="noopener noreferrer">Privacy Policy</a>
        &nbsp;
        &nbsp;Website By
        &nbsp;
        <a href="https://www.designbytemple.com" hrefLang="en" target="_blank" rel="noopener noreferrer">Temple</a>
        &nbsp;/
        &nbsp;
        <a href="http://flowmo.co/" hrefLang="en" target="_blank" rel="noopener noreferrer">Flowmoco</a>
      </div>
      <img className={styles.logo} src={footerLogo} alt="footer logo" />
    </div>
  </footer>
);

export default Footer;
