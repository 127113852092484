import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { map } from 'rxjs/operators';
import menuServicesHook from '../../hooks/menuServicesHook';
import WithHook from '../../util/WithHook';
import { scrollEvent } from '../../util/scrollTrack';
import MenuIcon from '../MenuIcon/MenuIcon';
import styles from './Header.module.scss';

import headerLogo from '../../images/icons/Timberland-header-logo.svg';
import emailIcon from '../../images/icons/Timberland-mail.svg';
import phoneIcon from '../../images/icons/Timberland-phone.svg';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.state = {
      menuOpen: false,
      scrollPos: 0,
      hide: false,
    };
  }

  componentDidMount() {
    const headerScrollEvent = scrollEvent.pipe(
      map(() => {
        const { scrollPos } = this.state;
        const topPos = typeof window !== 'undefined' ? (window.document.body.getBoundingClientRect()).top : 0;
        this.setState({ scrollPos: topPos });
        return (scrollPos > (0 - 500) || topPos > scrollPos);
      }),
    );

    headerScrollEvent
      .subscribe(show => this.setState(({ menuOpen }) => ({
        hide: !show,
        menuOpen: menuOpen ? false : menuOpen,
      })));
  }

  toggleMenu() {
    this.setState(({ menuOpen }) => ({ menuOpen: !menuOpen }));
  }

  closeMenu() {
    this.setState({ menuOpen: false });
  }

  render() {
    const { withHook: menuServices, transparentAtTop, className } = this.props;
    const { menuOpen, hide } = this.state;
    const scrollTop = typeof window !== 'undefined' ? (window.document.body.getBoundingClientRect()).top : 0;

    const headerClasses = [
      styles.header,
      menuOpen && styles.menuOpen,
      (transparentAtTop && (scrollTop > -50)) && styles.transparent,
      hide && styles.hide,
      className,
    ].join(' ');

    const createNavLink = (path, label) => (
      <Link
        to={path}
        className={styles.navLink}
        activeClassName={styles.activeNavLink}
      >
        {label}
      </Link>
    );

    return (
      <header className={headerClasses}>
        <div className={styles.navigationHeader}>
          <MenuIcon
            className={styles.menuIcon}
            open={menuOpen}
            onClick={this.toggleMenu}
          />
          <Link to="/" aria-label="timberland home" className={styles.headerLogo}>
            <img
              className={styles.headerLogo}
              src={headerLogo}
              alt="Timberland Joinery"
            />
          </Link>
          <div className={styles.centerSpace} />
          <div className={styles.contact}>
            <a
              href="mailto:sales@timberlandwindows.com"
              aria-label="email sales@timberlandwindows.com"
              className={styles.contactItem}
            >
              <div className={styles.contactIcon}>
                <img src={emailIcon} alt="email-icon" />
              </div>
              <span>sales@timberlandwindows.com</span>
            </a>
            <a
              href="tel:01566774200"
              aria-label="phone 01566774200"
              className={styles.contactItem}
            >
              <div className={styles.contactIcon}>
                <img src={phoneIcon} alt="phone-icon" />
              </div>
              <span>01566 774200</span>
            </a>
          </div>
          <ul className={styles.menu}>
            <li>{createNavLink('/', 'Home')}</li>
            {menuServices.map(({ key, name, slug }) => (
              <li key={key}>{createNavLink(`/service/${slug}`, name)}</li>
            ))}
            <li>{createNavLink('/materials', 'Materials')}</li>
            <li>{createNavLink('/testimonials', 'Testimonials')}</li>
            <li>{createNavLink('/#contact', 'Contact')}</li>
          </ul>
        </div>
      </header>
    );
  }
}

export default WithHook(menuServicesHook, Header);

Header.propTypes = {
  transparentAtTop: PropTypes.bool,
};

Header.defaultProps = {
  transparentAtTop: false,
};
