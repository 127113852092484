import React from 'react';
import PropTypes from 'prop-types';
import styles from './SectionContent.module.scss';

const SectionContent = ({ centered, className, children }) => (
  <div
    className={[
      styles.content,
      centered && styles.centered,
      className,
    ].join(' ')}
  >
    {children}
  </div>
);

SectionContent.propTypes = {
  centered: PropTypes.bool,
};

SectionContent.defaultProps = {
  centered: false,
};

export default SectionContent;
