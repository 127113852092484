import React from 'react';
import PropTypes from 'prop-types';
import styles from './Section.module.scss';

const Section = ({ theme, id, children }) => {
  const wrapperStyles = [
    styles.wrapper,
    (theme === 'dark' || theme === 'dark-bg') && styles.dark,
    theme === 'dark-bg' && styles.darkBG,
  ].join(' ');

  return (
    <section id={id} className={wrapperStyles}>
      {children}
    </section>
  );
};

Section.propTypes = {
  theme: PropTypes.oneOf(['dark', 'light', 'dark-bg']),
};

Section.defaultProps = {
  theme: 'light',
};

export default Section;
