import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import styles from './layout.module.scss';

const Layout = ({ transparentHeader, children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <div className={[styles.container, transparentHeader && styles.hasHero].join(' ')}>
        <Header
          className={styles.header}
          title={data.site.siteMetadata.title}
          transparentAtTop={transparentHeader}
        />
        <main className={styles.content}>
          {children}
        </main>
        <Footer className={styles.footer} />
      </div>
    )}
  />
);

Layout.propTypes = {
  transparentHeader: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Layout.defaultProps = {
  transparentHeader: false,
};

export default Layout;
